// Local JS imports
import Footer from './footer';
//import WhiteHeader from './white-header'; // alternative header with white background
import Header from './header';

// NodeJS package JS imports
import React from 'react';
import { Clock } from 'lucide-react';
import { MapPinHouse } from 'lucide-react';
import { MoveUpRight } from 'lucide-react';

// NodeJS package resource imports
import 'bootstrap/dist/css/bootstrap.min.css';


const JobListing = ({ title, description, fullTime }) => (
    <div className="job-listing mb-4 pb-4 border-bottom">
        <div className='d-flex justify-content-between'>
            <h3>{title}</h3>
            <h3>Apply <MoveUpRight strokeWidth = {3}/></h3>
        </div>
        <p>{description}</p>
        <div className="d-flex align-items-center">
            {/* <span className={`badge ${fullTime ? 'bg-primary' : 'bg-light text-dark border'} me-3`}>Full-Time</span> */}
            <button className="job-tags"><Clock className='clock-time'/>Full Time</button>
            <button className="job-tags"><MapPinHouse/>Remote Optional</button>
        </div>
    </div>
);

function Careers() {
    return (
        <div className='careers'>
            <Header useWhiteBackground="true" />
            <div className="container mt-5">
                {/* <h1 className="mb-3">Careers</h1> */}
                <div className="career-title d-flex flex-column ">
                    <h2 className="mb-3">Build some skills by joining our team!</h2>
                    <p className="lead">We are looking for passionate and talented individuals to join our team.</p>
                </div>
                <JobListing 
                    title="Software Engineering Intern"
                    description="We are looking for a student in Computer Science (or a related discipline) to join our team this coming summer as a Software Engineering Intern. You will work on cutting-edge tech, guaranteed. Responsibilities include developing new features, optimizing performance, and maintaining existing code. Must have fluency in one or more of the following: C++, React, Django, AWS-based DevOps workflows"
                    fullTime={true}
                />
            </div>
            <Footer />
        </div>
    );
}


export default Careers;
