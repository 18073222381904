import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import Header from "./header";
import Footer from "./footer";
import Button from 'react-bootstrap/Button';
import { motion } from 'framer-motion';
import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import { ArrowRight } from 'lucide-react';
import { ArrowLeft } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';



const NewsItem = ({ title, date, content, media }) => (
    <div className="news-item">
      <div className="news-item-date">
        {date}
      </div>
      <div className="news-item-content">
        <h2 className="news-item-title">{title}</h2>
        {media && (
          <div className="news-item-media">
            {media.type === 'image' ? (
              <img src={media.url} alt={title} />
            ) : media.type === 'video' ? (
              <video controls>
                <source src={media.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </div>
        )}
        <p className="news-item-text">{content}</p>
      </div>
      <div className="d-flex align-items-center news-item-divider"></div>
    </div>
);


function News() {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    useEffect(() => {
        const sampleNews = [
          {
            title: "encryptiest.com's News Page Launch Announcement!",
            date: "September 15, 2024",
            content: "We're excited to announce the launch of a new page on the encryptiest.com domain: encryptiest.com/news! When we have some more news, you'll see it here first haha.",
            media: { type: 'image', url: image1 }
          }
        ];
    
        setNews(sampleNews);
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = news.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(news.length / itemsPerPage);

    const nextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
        scroll.scrollTo('news-container', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: 500,
          containerId: 'news-container'
      });

    };

    const prevPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
        scroll.scrollTo('news-container', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
      });
    };



    return (
        <div className="news-page">
            <Header/>
            <div className="d-flex align-items-center justify-content-center hero-section">
                <div className="d-flex flex-column align-items-center text-center" id="slogan">
                    <motion.div
                        initial={{ y:50, opacity: 0.5, scale: 1}}
                        animate={{ y: 0, opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                    <h1 style={{ color: '#f5f5f5' }}>
                        Our Latest Updates
                    </h1>
                    </motion.div>
                </div>
            </div>

            <div className='spacer layer3'></div> 

            <div className="d-flex justify-content-center align-items-center news-info"id = "news">
                <h1 style={{ color: '#f5f5f5' }}>News</h1>
            </div>
    
            <div className="news-container" >
                {currentItems.map((item, index) => (
                    <NewsItem
                        key={index}
                        title={item.title}
                        date={item.date}
                        content={item.content}
                        media={item.media}
                    />
                ))}
                <div className="d-flex justify-content-around align-items-center mt-4 controls">
                    <Button 
                        onClick={prevPage} 
                        disabled={currentPage === 1}
                    >
                        <ArrowLeft className = "left-icon" />
                        Previous
                    </Button>
                    <span style={{ color: '#f5f5f5' }}>Page {currentPage} of {totalPages}</span>
                    <Button 
                        // href = "#news"  
                        onClick={nextPage} 
                        disabled={currentPage === totalPages}
      
                    >
                        Next
                        <ArrowRight className = "right-icon" />

                    </Button>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default News;
