// Local JS imports
import Header from "./header";
import Footer from "./footer";

// NodeJS package JS imports
import React from 'react'
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { motion } from 'framer-motion';

// Local resource imports
import './app.css';
import aboutImage from './images/bluewhale.png';

// NodeJS package resource imports
import 'bootstrap/dist/css/bootstrap.min.css';


function About() {
    return (
        <div className="about-page">
            <Header/>
            <div className = "d-flex align-items-center justify-content-center hero-section">
                <div className = "d-flex flex-column align-items-center text-center" id = "slogan">
                    <motion.div
                        initial={{ y:50, opacity: 0.5, scale: 1}}
                        animate={{ y: 0,opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                    <h1>
                        a closer look at encryptiest.
                    </h1>
                    </motion.div>
                </div>

            </div>

            <div className='spacer layer1'></div>

            <div className = "d-flex align-items-center justify-content-center about-content">


                <div className = "about-us">
                    <h2>About us</h2>
                    <p>We have a vision.</p>
                    <p>In our world, people aren't forced to look at advertisements online.</p>
                    <p>In our world, people see advertisements that are relevant to them, things they actually want to see, or they don't see any ads at all.</p>
                    <p></p>
                    <p>Realizing this vision requires us to bring many different groups together:</p>
                    <p>Engineers</p>
                    <p>Advertisers</p>
                    <p>Publishers</p>
                    <p>and You.</p>
                    <p></p>
                    <p>If you already share our vision, and have already joined us, then please see our <a href="/">partner website list</a> for a list of visionary websites who use our technology.</p>
                    <p></p>
                    <p>If you'd like to know more, please email us at hello@encryptiest.com. We are always happy to respond to inquiries from any curious party!</p>
                </div>

                <div className = "about-image">
                    <img 
                        src = {aboutImage}></img>
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default About;
