import './app.css';
import Cookies from "js-cookie";
import axios from 'axios';
import {Link} from "react-router-dom";
import Header from './header';
import Footer from './footer';
import React, { useEffect, useState } from 'react';


// Check if cookies are enabled
// console.log(navigator.cookieEnabled);

// When adding a new question, place it into the question_types array

//  window.location.href='/survey-confirmation'; <- redirection



function Survey(){

    const [responseData,setResponseData] = useState([]);
    const [questionData,setQuestionData] = useState([]);
    const [answerData,setAnswerData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem('question_data'));
        if (savedData) {
            setResponseData(savedData.map(item => ({ question: item.question, answer: item.userInputVal })));
            console.log('Loaded from localStorage:', savedData);
        }
    
        const obtainData = async () => {
            try {
                const res = await axios.get("http://127.0.0.1:8083/api/targeting-variable-read-all/");
                const questions = await Promise.all(res.data.map(async (item) => {
                    try {
                        const answerRes = await axios.get(`http://127.0.0.1:8083/api/targeting-variable-value-read-by-targeting-variable-id/${item._id}/`);
                        let answerLabels = [];
                        let answerValues = [];
                        if (item.type == "integer") {
                            const minAnswerValue = answerRes.data.find((element) => element.type == "integer_min_value").value;
                            const maxAnswerValue = answerRes.data.find((element) => element.type == "integer_max_value").value;
                            const numAnswers = maxAnswerValue - minAnswerValue + 1;
                            answerLabels = Array.from({length: numAnswers}, (_, i) => i + Number(minAnswerValue));
                        } else if (item.type == "categorical") { 
                            answerLabels = answerRes.data.map(answerItem => answerItem.value);
                        }
                        return {
                            question: item.name,
                            answersType: item.type,
                            answersLabels: answerLabels,
                            answersLength: answerLabels.length,
                            answersLengthBits: getBitValue(answerLabels.length)
                        };
                    } catch (err) {
                        console.log("Error fetching answers:", err.message);
                        return {
                            question: item.name,
                            answersType: null,
                            answersLabels: [],
                            answersLength: 0,
                            answersLengthBits: 0
                        };
                    }
                }));
                setQuestionData(questions);
            } catch (err) {
                console.log("Failed to fetch questions:", err.message);
            }
        };
        obtainData();
    }, []);

    const handleSelect = (event, question) => {
        const selectedValue = event.target.value;
        console.log(`Selected value for question "${question}": ${selectedValue}`);
        setResponseData(prevData => {
            const updatedData = prevData.filter(response => response.question !== question);
            return [...updatedData, { question: question, answer: selectedValue }];
        });
        //setCookie(question,selectedValue)
    };

    const getBitValue = (answerCount) => {
        return Math.ceil(Math.log2(answerCount))
    }

    const setCookie = (question, value) => {
        const questionItem = questionData.find(item => item.question === question);
        if (questionItem) {
            const bitValue = getBitValue(questionItem.answers.length);
            const cookieValue = JSON.stringify({ value, bitValue });
            Cookies.set(question, cookieValue, { expires: 365, sameSite: 'strict', secure: true });
            console.log(`Cookie set for ${question}: ${cookieValue}`);
        }
    };

    const saveLocal = () => {
        const storedData = responseData.map(({ question, answer }) => {
            const questionItem = questionData.find(item => item.question === question);
            let answerValue = -1;
            if (questionItem) {
                if (questionItem.answersType == "categorical") {
                    answerValue = questionItem.answersLabels.indexOf(answer);
                } else if (questionItem.answersType == "integer") {
                    answerValue = questionItem.answersLabels.findIndex((value) => value == answer);
                }
                return {
                    question: questionItem.question,
                    answerLabel: answer,
                    answerValue: answerValue,
                    answersLength: questionItem.answersLength,
                    answersLengthBits: questionItem.answersLengthBits
                };
            }
            return null;
        }).filter(item => item !== null).filter(item => item.answerValue !== -1);
        localStorage.setItem('question_data', JSON.stringify(storedData));
        console.log('Saved to localStorage:', storedData);
    };

    const [result, setResult] = useState(null);
    const [multiplyResult, setMultiplyResult] = useState(null);

    useEffect(() => {
        const initWasm = async () => {
        // Fetch and instantiate the WebAssembly module
        const response = await fetch(wasmURL);
        const { instance } = await WebAssembly.instantiateStreaming(response);

        // Call the add function from the WASM module
        const addResult = instance.exports.add(24, 24);
        setResult(addResult);

        const product = instance.exports.multiply(6, 7);
        setMultiplyResult(product);
        };

        initWasm();
    }, []);

    
    console.log(`Add Result: ${result}`);
    console.log(`Multiply Result: ${multiplyResult}`);

    return (

    <div className = "Survey">
        <Header/>
        <main>
            <div className="survey-description">
                <h1>Encryptiest Survey</h1>
                <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h2>
            </div>
            <div className="survey-questions">
            {questionData.map((item,index) => (
                <div key={index}>
                    <h2>{item.question}</h2>
                    <div className="custom-select">
                        <select onChange= {(e) => handleSelect(e,item.question)}>
                            <option value = "">Choose an Option</option>
                            {item.answersLabels.map((answer,idx) => (
                                <option key = {idx} value = {answer}>{answer}</option>
                            ))}
                        </select>
                    </div>
                </div>
            ))}
            </div>
            <button 
                className="savebutton" 
                onClick={() => {
                    saveLocal();
                    window.location.href = '/survey-confirmation';  
                }}
                >
                Save Encrypted Data Locally
            </button>
        </main> 
        <Footer/>
    </div>

    );
}

export default Survey;

