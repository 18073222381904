import './app.css';
import React, { useState } from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'
import Header from './header';
import Footer from './footer';
import { useNavigate } from 'react-router-dom';

function Login(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [userType, setUserType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const isValid = checkEmpty();
        if (!isValid){
            setErrorMessage('Login failed. Please check your username and password.');
            return;
        }

        try {
            const loginResponse = await axios.post('http://localhost:8083/api/user-login/', {
                username,
                password
            });
            
            const userResponse = await axios.get(`http://localhost:8083/api/user-get-type/${username}`, {});
            
            if (loginResponse.status >= 200 && loginResponse.status < 300 && userResponse.status >= 200 && userResponse.status < 300){
                
                Cookies.set('user_id', userResponse.data._id, {secure:true, sameSite:'strict', expires:365});
                
                setUsername('');
                setPassword('');
                setErrorMessage('');
                
                if (userResponse.data.user_type == "Advertiser"){
                    setUserType(userResponse.data.user_type);
                    navigate('/advertiser-home');
                    
                }
                else if (userResponse.data.user_type == "Publisher"){
                    setUserType(userResponse.data.user_type);
                    navigate('/publisher-home');
                }
                else {
                    console.log("Could not recognize userType.");
                }

            }
            else {
                setErrorMessage('Login failed. Please check your username and password.');
            }


        }
        catch (error) {
            if (error.response){
                console.error('Error response:', error.response.data);
                setErrorMessage('Login failed. Please check your username and password.');
            }
            else {
                console.error('Error:', error);
            }
        }
    }

    const checkEmpty = () => {
        let isValid = true;
        if (username === '' || password === ''){
            isValid = false;
        }
        return isValid;
    }

    return (
        <div>
            <Header/>
            <main>
                <div className="login">
                    <div className="login-error">
                        <h1>{errorMessage}</h1>
                    </div>

                <form onSubmit={handleSubmit}>
                    <div className="login-input">
                    <input id='username' value={username} onChange={(e)=>setUsername(e.target.value)}/>
                    <label>Username</label>
                    </div>

                    <div className="login-input">
                    <input id='password' value={password} type="password" onChange={(e)=>setPassword(e.target.value)}/>
                    <label>Password</label>
                    </div>

                    <button type='submit'>Login</button>

                </form>
                </div>
            </main>
            <Footer/>

        </div>
    )
}
export default Login;
