import './app.css';
import { marked } from 'marked';
import FaqMarkdown from './Faq.md'
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import Header from './header';
import Footer from './footer';

function sanitizeMarkdown(markdown) {
    return DOMPurify.sanitize(marked(markdown));
}


function FAQ(){
        let [content, setContent] = useState('');

        useEffect(() => {
            fetch(FaqMarkdown)
            .then((response) => response.text())
            .then((text) => setContent(text));
        }, []);
    
    return (
        <div className='faq'>
            
            <Header />
                
            <div className='faq-title'>
                <h1>FAQ</h1>
            </div>

            <div className = 'questions-block' dangerouslySetInnerHTML={{ __html: sanitizeMarkdown(content) }}></div>
            
            <Footer />

        </div>
    )
}

export default FAQ;