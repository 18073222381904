// index.js
import React from "react"
import ReactDOM from "react-dom/client"
import axios from 'axios'

//react bootstrap component library
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './app';
import FAQ from "./FAQ";
import News from "./news";
import About from "./about";
import Careers from "./careers";
import ConsentPopup from "./consentpopup";
import Survey from "./Survey";
import SurveyConfirmation from "./surveyconfirmation";
import Investors from "./investors";
import AdvertiserSignup from "./AdvertiserSignup";
import EmailConfirmation from "./EmailConfirmation";
import Websites from "./websites";
import AdvertAdd from "./advertiser-add";
import CampaignAdd from "./campaign-add";
import Introduction from "./introduction";
import PublisherSignup from "./PublisherSignup";
import ConfirmationLogin from "./confirmation-login";
import PublisherHome from "./publisher-home";
import AdvertiserHome from "./advertiser-home";
import Login from "./login";
import AdvertiserRedirect from "./advertiser-redirect";
import PublisherRedirect from "./publisher-redirect";
import GetStarted from "./getStarted";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path : "/FAQ",
    element: <FAQ />,
  },
  {
    path : "/news",
    element: <News />,
  },
  {
    path : "/about",
    element: <About />,
  },
  {
    path: "/Investors",
    element: <Investors />,
  },
  {
    path : "/careers",
    element: <Careers />,
  },
  {
    path : "/consent-popup",
    element : <ConsentPopup />,
  },
  {
    path : "/Survey",
    element: <Survey />,
  
  },
  {
    path : "/survey-confirmation",
    element: <SurveyConfirmation />,
  },
  {
    path: "/websites",
    element: <Websites />,
  },
  {
    path: "/advertisement-add",
    element: <AdvertAdd />,
  },
  {
    path: "/campaign-add",
    element: <CampaignAdd />
  },
  {
    path: "/introduction",
    element: <Introduction />
  },
  {
    path : "/advertiser-signup",
    element: <AdvertiserSignup />,
  },
  {
    path : "/email-confirmation",
    element : <EmailConfirmation />,
  },
  {
    path : "/confirmation-login",
    element : <ConfirmationLogin />,
  },
  {
    path: "/publisher-signup",
    element: <PublisherSignup />
  },
  {
    path: "/publisher-home",
    element: <PublisherHome />
  },
  {
    path: "/advertiser-home",
    element: <AdvertiserHome />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/advertiser-redirect",
    element: <AdvertiserRedirect />
  },
  {
    path: "/publisher-redirect",
    element: <PublisherRedirect />
  },
  {
    path: "/get-started", 
    element: <GetStarted></GetStarted>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);

//axios.get('/django/api/students')
//  .then(function (response) {
//    // handle success
//    console.log(response);
//  })
//  .catch(function (error) {
//    // handle error
//    console.log(error);
//  })
//  .finally(function () {
//    // always executed
//  });
